import React, { lazy } from "react";

import { DictionaryKey } from "src/i18n";
import { FeScheme, PAGES } from "src/components/routes/types";
import { EnumsRole } from "src/_generated";

export type Roles = "guest" | EnumsRole;
export const pagesRedirect: Record<Roles, FeScheme> = {
  guest: [{ scheme: PAGES.LOGIN }],
  user: [{ scheme: PAGES.ROOT }],
};
export type PageRoute = {
  access: "all" | ["user"];
  title?: DictionaryKey;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
};

export const pages: Record<PAGES, PageRoute> = {
  [PAGES.ROOT]: {
    access: ["user"],
    title: "src.components.routes.pages.Root.title",
    component: lazy(() => import("src/components/routes/pages/Root")),
  },
  [PAGES.LOGIN]: {
    access: "all",
    title: "src.components.routes.pages.Login.title",
    component: lazy(() => import("src/components/routes/pages/Login")),
  },
  [PAGES.PRELONG]: {
    access: "all",
    title: "src.components.routes.pages.Prelong.title",
    component: lazy(() => import("src/components/routes/pages/Prelong")),
  },
  [PAGES.NOT_FOUND]: {
    access: "all",
    title: "src.components.routes.pages.NotFound.title",
    component: lazy(() => import("src/components/routes/pages/NotFound")),
  },
};
