export const base = {
  "src.components.routes.pages.Root.title": "Customer Space",
  "src.components.routes.pages.Login.title": "Zadejte své telefonní číslo",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "error.name.required": "test",
  "src.components.organisms.NotFoundBlock.title": "Stránka nenalezena",
  "error.login.required": "Telefon je povinný",
  "error.comment.required": "Komentář je povinný",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.organisms.forms.LoginForm.placeholder":
    "777 777 777",
  "src.components.organisms.forms.LoginForm.label": "Tvůj telefon",
  "src.components.organisms.forms.LoginForm.btn": "Přihlásit se",
  "error.checkbox.required": "required",
  "src.components.molecules.InfoAlert.info":
    "Váš jídelníček můžete začít měnit nejdříve 7 dní ode dneška.",
  "src.components.molecules.InfoAlert.warning":
    "Poznámka: Pokrmy mohou obsahovat stopy alergenů.",
  "src.components.organisms.forms.PhoneForm.placeholder": "Telefon",
  "src.components.organisms.forms.PhoneForm.label": "Telefon",
  "src.components.organisms.forms.PhoneForm.btn": "Přihlásit se",
  "src.components.organisms.PhoneModal.title": "Zadejte své telefonní číslo",
  "src.components.organisms.forms.CommentForm.placeholder": "Toto jídlo mi opravdu chutnalo.",
  "src.components.organisms.forms.CommentForm.label": "",
  "src.components.organisms.forms.CommentForm.submitBtn": "Uložit",
  "src.components.organisms.forms.CommentForm.btn": "Zrušit",
  "src.components.organisms.CommentModal.title": "Přidat komentář",
  "src.components.organisms.RateModal.title":
    "Pomozte nám udělat NutritionPro chutnější",

  "src.components.organisms.forms.RateForm.placeholder": "Text",
  "src.components.organisms.forms.RateForm.label": "Text",
  "error.rate.required": "test",
  "src.components.organisms.forms.RateForm.submitBtn": "Uložit",
  "src.components.organisms.forms.RateForm.btn": "Zrušit",
  "src.components.organisms.forms.RateForm.rate": "Hodnocení dodaní",
  "src.components.organisms.forms.RateForm.mark": "Hodnocení pokrmu",
  "src.components.organisms.ExpectionsModal.title": "Seznam vyjímek",
  "src.components.molecules.ExpectionsAlert.exchange":
    "Zvolte ingredience, které chcete vyřadit a my se vám pokusíme sestavit nové menu bez nich.",
  "src.components.molecules.ExpectionsAlert.nuts":
    "Jestliže nebudou žádné náhrady, pokrm zůstane nezměněný.",
  "src.components.organisms.forms.ExpectionsForm.submit": "Uložit",
  "src.components.organisms.forms.ExpectionsForm.btn": "Zrušit",
  "src.components.molecules.ChangeFoodAccordion.title": "Změna jídla",
  "src.components.molecules.HeaderInfo.title": "Dobrý NutritionPro den {name}!",
  "src.components.molecules.HeaderInfo.text":
    "Dnes jsme připravili jídelníček speciálně pro Vás.",
  "src.components.organisms.RateModal.btn":
    "Pomozte nám udělat NutritionPro chutnější",
  "src.components.organisms.Header.btn": "Odhlásit",
  "src.components.organisms.Header.installBtn": "Přidat na plochu",
  "src.components.organisms.ExpectionsModal.btn": "Vyloučit ingredience",
  "src.components.molecules.EmptyDays.title":
    "Vaše nabídka pro nadcházející dodávku ještě není připravena",
  "src.components.molecules.EmptyDays.subTitle":
    "Nebojte se, objeví se blíže k datům dodání",
  "src.components.molecules.DeliveryInfoBlock.day":
    "Poslední den objednávek: {value}",
  "src.components.molecules.DeliveryInfoBlock.address":
    "Dodací adresa: {value}",
  "src.components.molecules.DeliveryInfoBlock.time":
    "Preferovaný čas doručení: {value}",
  "src.components.molecules.TotalDayCardInfo.text": "Celkem za den",
  "src.components.molecules.MenuCard.breakfast": "Snídaně",
  "src.components.molecules.MenuCard.snack1": "Svačina 1",
  "src.components.molecules.MenuCard.lunch": "Oběd",
  "src.components.molecules.MenuCard.snack2": "Svačina 2",
  "src.components.molecules.MenuCard.dinner": "Večeře",
  "src.components.atoms.DishIsHotBadge.text": "Ohřívat",
  "src.components.atoms.DishChoicedBadge.text": "Vybráno Vámi",

  "src.components.molecules.Nutritions.kcal": "KCal",
  "src.components.molecules.Nutritions.prot": "Bílkoviny",
  "src.components.molecules.Nutritions.fat": "Tuky",
  "src.components.molecules.Nutritions.carb": "Sacharidy",
  "src.components.molecules.ChoiceOptionFooterInformer.text":
    "Tento pokrm je podáván {date} na {meal}",

  "src.components.molecules.ChoiceOptionFooterInformer.monday": "Pondělí",
  "src.components.molecules.ChoiceOptionFooterInformer.tuesday": "úterý",
  "src.components.molecules.ChoiceOptionFooterInformer.wednesday": "středa",
  "src.components.molecules.ChoiceOptionFooterInformer.thursday": "čtvrtek",
  "src.components.molecules.ChoiceOptionFooterInformer.friday": "pátek",
  "src.components.molecules.ChoiceOptionFooterInformer.saturday": "sobota",
  "src.components.molecules.ChoiceOptionFooterInformer.sunday": "neděle",
  "src.components.molecules.ChangeFoodAccordion.save": "Uložit",
  "src.components.organisms.CommentModal.btn": "Přidat komentář",
  "src.components.molecules.InfoAlert.google":
    "Ohodnoťte NutritionPro na Google",
    "src.components.molecules.DeliveryInfoBlock.title":"Aktuální stav programu",
    "src.components.organisms.MenuDayCarousel.title":"Váš jídelníček",
    "src.components.routes.pages.Login.description":"pro přihlášení do klientského portálu NutritionPro",
    "src.components.routes.pages.Root.prelongBtn":"Prodloužít program online",
    "src.components.molecules.DeliveryInfoBlock.daysLeft":"( zbyvá {value} dní)",
    "error.paymentMethod.required": "Payment method je vyžadováno.",
    "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Zabezpečenou platbu poskytuje",
    "src.components.organisms.OnlineOrderThiredStep.platba": "Zvolte platbu",
    "src.components.organisms.OnlinePrelongBlock.userInfo": "Osobní údaje",
    "src.components.organisms.OnlinePrelongBlock.programTitle": "Můj program",
    "src.components.organisms.OnlinePrelongBlock.length":" Prodloužit o {value} dní",
    "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Shrnutí objednávky",
    "src.components.templates.OnlinePrelongTemplate.title":
    " Prodloužení programu",
    "src.components.templates.OnlineOrderMobileTemplate.close": "Zrušit",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Cena za menu (bez dopravy):",
    "src.components.atoms.ConfirmPrice.price": "{price} Kč",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Cena dopravy za den:",
    "src.components.atoms.ConfirmPrice.text": "Cena celkem (vč. DPH)",
    "src.components.molecules.OrderRightCard.pocrovat": "Pokračovat",
    "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Cena za celé období:",
    "src.components.routes.pages.Prelong.title":"Preelong",
    "src.components.organisms.OnlinePrelongBlock.days":"{value} dní",
    "src.components.organisms.OnlinePrelongBlock.porcel":"{value} porcel",
  };
